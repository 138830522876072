import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    userRole: '',
    orgName: '',
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getUserRole: state => state.userRole,
    getOrgName: state => state.orgName,
  },
  mutations: {
    UPDATE_USER_ROLE(state, role) {
      state.userRole = role
    },
    UPDATE_ORG_NAME(state, name) {
      state.orgName = name
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    updateUserRole: (context, role) => {
      context.commit('UPDATE_USER_ROLE', role)
    },
    updateOrgName: (context, name) => {
      context.commit('UPDATE_ORG_NAME', name)
    },
  },
}
