import axios from '@axios'
import JwtService from './jwtService'

function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}

const { jwt } = useJwt(axios, {
  loginEndpoint: '/auth/login',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/auth/refresh-token',
  logoutEndpoint: '/auth/logout',
  getUserInfoEndpoint: '/me',
  getSocialLoginEndpoint: '/login/',
})
export default jwt
