export default [
  // Organization Modules
  {
    path: '/organization/list',
    name: 'organization',
    component: () => import('@/views/organization/list/OrganizationList.vue'),
    meta: {
      pageTitle: 'Organization List',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Organization List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add/organization',
    name: 'add-organization',
    component: () => import('@/views/organization/add/OrganizationAdd.vue'),
    meta: {
      pageTitle: 'Add Organization',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Organization List',
          to: '/organization/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/match/results/:id',
    name: 'results',
    component: () => import('@/views/matches/result/Result.vue'),
    meta: {
      pageTitle: 'Add Result',
      resource: 'Matches',
      breadcrumb: [
        {
          text: 'Match',
          to: { name: 'matches' },
        },
        {
          text: 'Result',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit/organization/:id',
    name: 'edit-organization',
    component: () => import('@/views/organization/edit/OrganizationEdit.vue'),
    meta: {
      pageTitle: 'Edit Organization',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Organization List',
          to: '/organization/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/view/organization/:id',
    name: 'view-organization',
    component: () => import('@/views/organization/view/OrganizationView.vue'),
    meta: {
      pageTitle: 'View Organization',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Organization List',
          to: '/organization/list',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  // Rules Modules
  {
    path: '/manage/rules',
    name: 'manage-rules',
    component: () => import('@/views/rules/list/RuleList.vue'),
    meta: {
      pageTitle: 'Rules Management',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Rules Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add/rule',
    name: 'add-rule',
    component: () => import('@/views/rules/add/RuleAdd.vue'),
    meta: {
      pageTitle: 'Rule Add',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Rules',
          to: '/manage/rules',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit/rule/:id',
    name: 'edit-rule',
    component: () => import('@/views/rules/edit/RuleEdit.vue'),
    meta: {
      pageTitle: 'Rule Edit',
      resource: 'Admin',
      breadcrumb: [
        {
          text: 'Rules',
          to: '/manage/rules',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // Match Modules
  {
    path: '/matches/list',
    name: 'matches',
    component: () => import('@/views/matches/list/MatchList.vue'),
    meta: {
      pageTitle: 'Matches List',
      resource: 'Matches',
      breadcrumb: [
        {
          text: 'Matches List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add/match',
    name: 'add-match',
    component: () => import('@/views/matches/add/MatchAdd.vue'),
    meta: {
      pageTitle: 'Add Match',
      resource: 'Matches',
      breadcrumb: [
        {
          text: 'Matches List',
          to: '/matches/list',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit/match/:id',
    name: 'edit-match',
    component: () => import('@/views/matches/edit/MatchEdit.vue'),
    meta: {
      pageTitle: 'Edit Match',
      resource: 'Matches',
      breadcrumb: [
        {
          text: 'Matches List',
          to: '/matches/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Chat Modules
  {
    path: '/chats',
    name: 'chats',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      pageTitle: 'Chats List',
      resource: 'Chat',
      breadcrumb: [
        {
          text: 'Chats List',
          active: true,
        },
      ],
    },
  },

  // Videos
  {
    path: '/videos',
    name: 'videos',
    component: () => import('@/views/videos/videos.vue'),
    meta: {
      pageTitle: 'Videos',
      resource: 'Chat',
      breadcrumb: [
        {
          text: 'Videos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/videos/add',
    name: 'video-add',
    component: () => import('@/views/videos/add.vue'),
    meta: {
      pageTitle: 'Videos',
      resource: 'Chat',
      breadcrumb: [
        {
          text: 'Videos',
          to: { name: 'videos' },
        },
        {
          text: 'Add Video',
          active: true,
        },
      ],
    },
  },
  {
    path: '/video/:slug',
    name: 'video-details',
    component: () => import('@/views/videos/details.vue'),
    meta: {
      pageTitle: 'Videos',
      resource: 'Chat',
      breadcrumb: [
        {
          text: 'Videos',
          to: { name: 'videos' },
        },
        {
          text: 'Video Details',
          active: true,
        },
      ],
    },
  },

  // settings
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },

  // wallet
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Wallet',
          active: true,
        },
      ],
    },
  },
  // transactions
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: {
      pageTitle: 'Transactions',
      resource: 'Auth',
      breadcrumb: [
        {
          text: 'Transactions',
          active: true,
        },
      ],
    },
  },
  // Tournament Modules
  {
    path: '/tournaments',
    name: 'tournaments',
    component: () => import('@/views/tournament/TournamentList.vue'),
    meta: {
      pageTitle: 'Tournaments',
      resource: 'Matches',
      breadcrumb: [
        {
          text: 'Tournaments',
          active: true,
        },
      ],

    },
  },
  {
    path: '/tournaments/archived',
    name: 'tournaments-archived',
    component: () => import('@/views/tournament/TournamentArchived.vue'),
    meta: {
      pageTitle: 'Archived Tournaments',
      resource: 'Matches',
      navActiveLink: 'tournaments',
      breadcrumb: [
        {
          text: 'Tournaments',
          to: { name: 'tournaments' },
        },
        {
          text: 'Archived Tournaments',
          active: true,
        },
      ],

    },
  },

  {
    path: '/tournaments/add',
    name: 'tournament-add',
    component: () => import('@/views/tournament/AddTournament.vue'),
    meta: {
      pageTitle: 'Create Tournament',
      resource: 'Matches',
      navActiveLink: 'tournaments',
      breadcrumb: [
        {
          text: 'Tournaments',
          to: { name: 'tournaments' },
        },
        {
          text: 'Create Tournament',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tournaments/:tournament',
    name: 'tournament-details',
    component: () => import('@/views/tournament/details/Tournament.vue'),
    meta: {
      pageTitle: 'Tournament Title',
      resource: 'Matches',
      navActiveLink: 'tournaments',
      breadcrumb: [
        {
          text: 'Tournaments',
          to: { name: 'tournaments' },
        },
        {
          text: 'Tournament Title',
          active: true,
        },
      ],
    },
  },

]
